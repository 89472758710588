import { head } from '~/utils/ajax';
import { LocalStorageHelper } from '~/utils/storage';
import { LOCAL_STORAGE_WOWS_CLIENT_DEV } from '~/utils/keys';
import { MOBILE_RESOLUTION, SUPERSHIP_ICON } from '~/const';

const settings = window?.metashop?.settings;
let localeLanguageCode = settings.languageCode;

if (localeLanguageCode === 'en') {
    if (settings.realm === 'us') {
        localeLanguageCode = 'en-US';
    } else {
        localeLanguageCode = 'en-GB';
    }
}

const dateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
};
const dateFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};

// @ts-ignore
const dateFormat = new Intl.DateTimeFormat(localeLanguageCode, settings.debug ? dateTimeFormatOptions : dateFormatOptions);
// @ts-ignore
const dateTimeFormat = new Intl.DateTimeFormat(localeLanguageCode, dateTimeFormatOptions);

const shortDateFormat = new Intl.DateTimeFormat(localeLanguageCode, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
});

export const shortTime = new Intl.DateTimeFormat(localeLanguageCode, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
});

export const getNowTime = () => {
    return Math.floor(Date.now() / 1000);
};

export const redirectToLogin = (): void => {
    const nextTo = encodeURIComponent(document.location.pathname + document.location.search);
    document.location.href = `${settings.urls.loginUrl}?next=${nextTo}`;
    console.warn('redirect', settings.urls.loginUrl, `${settings.urls.loginUrl}?next=${nextTo}`);
};

export const redirectToLoginForPurchase = (bundleId: number) => {
    updateSearchParam({
        bundleId: bundleId,
        purchase: true,
        from_auth: true,
    });

    redirectToLogin();
};

export const isObject = (object: Record<string, any> | string | number | boolean | any): boolean => {
    return Object.prototype.toString.call(object) === '[object Object]';
};

export const isInGameBrowser = window.navigator.userAgent.includes('WOWS/1') || LocalStorageHelper.get(LOCAL_STORAGE_WOWS_CLIENT_DEV);
export const isPortPreviewEnabled = isInGameBrowser && !settings.isPortPreviewDisabled;

export let isMobileOrTabletWindow = document.body.offsetWidth <= MOBILE_RESOLUTION;
export const isMobileDisplay = window.screen.availWidth <= 479;
export const is4kDisplay = window.screen.availWidth > 2560;

export function updateFlagMobileOrTablet(matches: boolean) {
    isMobileOrTabletWindow = matches;
}

export const rand = (min: number, max: number): number => {
    const rand = min + Math.random() * (max + 1 - min);

    return Math.floor(rand);
};

export const getStaticUrl = (url: string): string => {
    return url;
};

export const getCDNUrl = (url: string, useMirror = false) => {
    const cdn = useMirror ? settings.urls.additionalCdn : settings.urls.cdn;
    return cdn + url;
};

export const getLevelFromRomanFormat = (level: number): string | number => {
    switch (level) {
        case 1:
            return 'I';
        case 2:
            return 'II';
        case 3:
            return 'III';
        case 4:
            return 'IV';
        case 5:
            return 'V';
        case 6:
            return 'VI';
        case 7:
            return 'VII';
        case 8:
            return 'VIII';
        case 9:
            return 'IX';
        case 10:
            return 'X';
        case 11:
            return SUPERSHIP_ICON;
        default:
            return level;
    }
};

export const getDeepProperty = (property: string, object: Record<string, any>): Record<string, string> => {
    const properties = property.split('.');
    let current = object;

    for (let i = 0; i < properties.length; ++i) {
        if (current[properties[i]] == undefined) {
            return undefined;
        } else {
            current = current[properties[i]];
        }
    }

    return current;
};

export const formatNumber = (number: number, toFixed?: number): string => {
    const format = window?.formatNumber;
    let result: string;

    if (format) {
        result = format(number, toFixed);
    } else {
        result = number.toLocaleString('en').replace(/[,|.]/g, (separator) => (separator === ',' ? '\u00A0' : ','));
    }

    return result;
};

export const toInt = (number: string): number => Number(number.replace(/\D/g, ''));

export const localizedDate = (date: Date): string => {
    return dateFormat.format(date);
};

export const localizedDateTime = (date: Date): string => {
    return dateTimeFormat.format(date);
};

export const localizedDateWithFormat = (date: Date, options?: Intl.DateTimeFormatOptions): string => {
    const dateFormat = new Intl.DateTimeFormat(localeLanguageCode, options);
    return dateFormat.format(date);
};

export const localizedShortDate = (date: Date): string => {
    return shortDateFormat.format(date);
};

export const arrayToObjectByKey = <T extends Record<string, any>, K extends keyof T>(array: T[], key: K): Record<string, T> => {
    return array.reduce((result: Record<string, T>, item) => {
        return (result[item[key]] = item) && result;
    }, {});
};

export const getDiapasonOfNumbers = (numbers: number[], delimiter = '-') => {
    numbers = numbers.sort((a, b) => (b > a ? -1 : 1));
    let memory: number = null;

    return numbers.reduce((result, number, index) => {
        if (number + 1 === numbers[++index]) {
            memory === null && (memory = number);
        } else {
            result.push(memory || memory === 0 ? `${memory}${delimiter}${number}` : number);
            memory = null;
        }

        return result;
    }, []);
};

export const getNumbersFromDiapason = (diapason: string, delimiter = '-') => {
    const [firstNumber, lastNumber] = diapason.split(delimiter);
    const array: number[] = [+firstNumber];

    for (let i = +firstNumber + 1; i <= +lastNumber; i++) {
        array.push(i);
    }

    return array;
};

export const flat = function (arr: any[]): any[] {
    const array: any[] = [];
    const stack = [arr];

    while (stack.length > 0) {
        const item = stack.pop();

        if (!Array.isArray(item)) {
            array.push(item);
        } else {
            item.forEach((i) => {
                stack.push(i);
            });
        }
    }

    return array.reverse();
};

export const getClientPath = (path: string) => {
    return `wowsdata://${path}`;
};

type STATIC_PATH_TYPE = {
    static?: string;
    cdn?: string;
    client: string;
};
export const getStaticPathFromClientOrWeb = (options: STATIC_PATH_TYPE) => {
    if (isInGameBrowser) {
        return getClientPath(options.client);
    }

    if (options.cdn) {
        return options.cdn;
    }

    return getStaticUrl(options.static);
};

export const getSearchParam = (key: string) => {
    const params = new URLSearchParams(document.location.search);
    return params.get(key);
};

export const updateSearchParam = (params: Record<string, string | number | boolean>) => {
    let currentParams: string[] = document.location.search.slice(1, document.location.search.length)?.split('&') || [];

    currentParams = currentParams.filter((param) => {
        const [key] = param.split('=');
        return !params.hasOwnProperty(key);
    });

    Object.keys(params).forEach((key) => {
        if (!params[key]) {
            return;
        }

        currentParams.push(`${key}=${params[key]}`);
    });

    currentParams = currentParams.filter((param) => !!param.trim().length);

    const searchParamsString = currentParams.join('&');
    const searchParams = `?${searchParamsString}`;

    history.replaceState(null, null, searchParams);
};

export const uuid4 = () => {
    return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (parseInt(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))).toString(16));
};

export const randomHash = (length = 20) => {
    return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
};

export const intersection = (array1: any[], array2: any[]) => {
    return array1.filter((item) => array2.includes(item));
};

export const diff = (array1: any[], array2: any[]) => {
    return array1.filter((x) => !array2.includes(x)).concat(array2.filter((x) => !array1.includes(x)));
};

export const isEmptyObject = (object: Record<string, any>) => !Object.keys(object || {})?.length;

export const isExistsFileOnRemoteAddress = async (url: string): Promise<boolean> => {
    try {
        const response = await head(url);
        return response?.status === 200;
    } catch (e) {
        return false;
    }
};

export const convertLanguageCode = (code: string) => {
    return code.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase());
};
